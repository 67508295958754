// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Leaderboard from './pages/Leaderboard';
import BonusForum from './pages/BonusForum';
import Preloader from './components/Preloader/preloader'; // Import Preloader
import './global.css';

const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // Adjust duration as needed

        return () => clearTimeout(timer);
    }, []);

    return (
        <Router>
            {loading ? <Preloader /> : null}
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/leaderboard/diceblox" element={<Leaderboard />} />
                <Route path="/bonus" element={<BonusForum />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
