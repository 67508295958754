// src/components/Footer/Footer.js

import React from 'react';
import { FaYoutube, FaTwitch, FaDiscord, FaTwitter } from 'react-icons/fa';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-sections">
                <div className="follow-section">
                    <h4>Follow Me</h4>
                    <div className="social-icons">
                        <a href="https://youtube.com/yourchannel" target="_blank" rel="noopener noreferrer">
                            <FaYoutube />
                        </a>
                        <a href="https://www.twitch.tv/fyjordan" target="_blank" rel="noopener noreferrer">
                            <FaTwitch />
                        </a>
                        <a href="https://discord.com/invite/yourserver" target="_blank" rel="noopener noreferrer">
                            <FaDiscord />
                        </a>
                        <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
                            <FaTwitter />
                        </a>
                    </div>
                </div>
                <div className="info-section">
                    <h4>Information</h4>
                    <ul>
                        <li><a href="/terms">Terms of Service</a></li>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>
                    © {new Date().getFullYear()}{' '}
                    <a href="https://jordanrewards.com" target="_blank" rel="noopener noreferrer" className="light-blue">
                        jordanrewards.com
                    </a>. All rights reserved.
                </p>
                <p>
                Made by <a href="https://whyomar.com" target="_blank" rel="noopener noreferrer">@WhyOmar</a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
