import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Leaderboard.module.css';
import './Leaderboard.css'; // Make sure you add relevant CSS for styling

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [endsAt, setEndsAt] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get('https://api.jordanrewards.com/leaderboard/diceblox');
        if (response.data.success) {
          setLeaderboardData(response.data.data.leaderboard);
          setRewards(response.data.data.rewards);
          setEndsAt(new Date(response.data.data.endsAt));
        } else {
          throw new Error('Failed to load leaderboard data.');
        }
      } catch (err) {
        console.error('Error fetching leaderboard:', err);
        setError(err.message || 'Failed to load leaderboard data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (endsAt) {
        const now = new Date();
        const distance = endsAt - now;

        if (distance <= 0) {
          setTimeRemaining('Leaderboard has ended');
          clearInterval(interval);
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endsAt]);

  if (loading) return <div className="loading">Loading leaderboard...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="leaderboard-container">
                              <div className="image-overlaydd"></div>
                              <div className="image-overlayddd"></div>
      {/* Title with Gradient */}
      <h1 className="leaderboard-title">$1,000 DiceBlox Leaderboard</h1>

      <p className="gradient-texters">Switch between 5x different leaderboards</p>
<div className="logo-containerend" style={{ display: 'flex', justifyContent: 'space-between', margin: '-14px 0' }}>
</div>
      
      <div className="logo-containerend" style={{ display: 'flex', justifyContent: 'space-between', margin: '26px 0' }}>
    <a href="/leaderboard/diceblox" className="logo-link">
        <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
            <img src={`${process.env.PUBLIC_URL}/assets/download.png`} alt="Logo 1" style={{ width: '80%', height: 'auto', margin: '0 auto 0 30px' }} />
            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #d3d3d3, transparent)' }}></div>
        </div>
    </a>

    <a href="/leaderboard/diceblox" className="logo-link">
        <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
            <img src={`${process.env.PUBLIC_URL}/assets/download2.png`} alt="Logo 2" style={{ width: '80%', height: 'auto', margin: '0 auto 0 30px' }} />
            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #ff007f, transparent)' }}></div>
        </div>
    </a>

    <a href="/leaderboard/diceblox" className="logo-link">
        <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
            <img src={`${process.env.PUBLIC_URL}/assets/diceblox.png`} alt="Logo 3" style={{ width: '80%', height: 'auto', margin: '0 auto 0 30px' }} />
            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, transparent, #ffd700)', }}></div>
            <div style={{ position: 'absolute', bottom: '-20.1px', left: '50%', transform: 'translateX(-50%)', width: '0', height: '0', borderLeft: '25px solid transparent', borderRight: '25px solid transparent', borderTop: '25px solid #ffd700' }}></div>
        </div>
    </a>

    <a href="/leaderboard/diceblox" className="logo-link">
        <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
            <img src={`${process.env.PUBLIC_URL}/assets/download4.png`} alt="Logo 4" style={{ width: '80%', height: 'auto', margin: '0 auto 0 30px' }} />
            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #9966cc, transparent)' }}></div>
        </div>
    </a>

    <a href="/leaderboard/diceblox" className="logo-link">
        <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
            <img src={`${process.env.PUBLIC_URL}/assets/download3.png`} alt="Logo 5" style={{ width: '80%', height: 'auto', margin: '0 auto 0 30px' }} />
            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #4169e1, transparent)' }}></div>
        </div>
    </a>
</div>

<a
  href="https://diceblox.com/r/jordan"
  target="_blank"
  rel="noopener noreferrer"
  className={styles.signupButton}
>
  Sign Up
</a>



      {/* Podium for Top 3 Users - Adjusted Order */}
   <div className="podium">
    {/* Second Place */}
    <div className="podium-spot spot-2">
        <img className="podium-avatar" src={leaderboardData[1]?.avatar} alt="Avatar 2" />
        <div className="podium-username">{leaderboardData[1]?.username}</div>
        <div className="podium-wagered-container">
            <span className="podium-wagered">Wagered: R${leaderboardData[1]?.wagered}</span>
        </div>
        <div className="podium-reward">Reward: ${rewards[1] || 'N/A'}</div>
    </div>

    {/* First Place - Centered and elevated */}
    <div className="podium-spot spot-1">
        <img className="podium-avatar" src={leaderboardData[0]?.avatar} alt="Avatar 1" />
        <div className="podium-username">{leaderboardData[0]?.username}</div>
        <div className="podium-wagered-container">
            <span className="podium-wagered">Wagered: R${leaderboardData[0]?.wagered}</span>
        </div>
        <div className="podium-reward">Reward: ${rewards[0] || 'N/A'}</div>
    </div>

    {/* Third Place */}
    <div className="podium-spot spot-3">
        <img className="podium-avatar" src={leaderboardData[2]?.avatar} alt="Avatar 3" />
        <div className="podium-username">{leaderboardData[2]?.username}</div>
        <div className="podium-wagered-container">
            <span className="podium-wagered">Wagered: R${leaderboardData[2]?.wagered}</span>
        </div>
        <div className="podium-reward">Reward: ${rewards[2] || 'N/A'}</div>
    </div>
</div>

<div className="timer">
        <h3>Time Remaining: {timeRemaining}</h3>
      </div>


      {/* Leaderboard Table for 4th to 10th Place */}
      <div className="leaderboard-table">
        <table>
          <thead>
          <tr>
    <th class="header-rank">Rank</th>
    <th class="header-username">Username</th>
    <th class="header-wagered">Wagered</th>
    <th class="header-reward">Reward</th>
  </tr>
          </thead>
          <tbody>
            {leaderboardData.slice(3, 10).map((user, index) => (
                <tr key={user.id}>
                    <td>{index + 4}</td> {/* Adjusted to start from 4th place */}
                    <td className="username-cell">
                        <img
                            className="leaderboard-avatar"
                            src={user.avatar}
                            alt={`${user.username}'s avatar`}
                        />
                        <span className="username-text">{user.username}</span>
                    </td>
                    <td>R${user.wagered}</td>
                    <td>${rewards[index + 3] || 'N/A'}</td> {/* Corrected index for rewards */}
                </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leaderboard;
