// BonusForum.js
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './BonusForum.css';

const BonusForum = () => {
  const [discordUsername, setDiscordUsername] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [selectedSite, setSelectedSite] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // Track if the message is success
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // Obfuscated webhook URL parts
  const urlParts = [
    "https://disc", 
    "ord.com/api/webhooks/", 
    "1303419852043714671/",
    "DNUQr4qH49LD", 
    "WRzA2EHWzV82Akr", 
    "dCXWT4KOvC80jEJLDOfdqYZtjx", 
    "4GX98PuC2B_3DTw"
  ];

  // Function to reconstruct the webhook URL from parts
  const getWebhookUrl = () => urlParts.join('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!discordUsername || !depositAmount || !selectedSite) {
      setMessage("Please fill in all fields.");
      setIsSuccess(false);
      return;
    }

    if (!recaptchaValue) {
      setMessage("Please complete the reCAPTCHA.");
      setIsSuccess(false);
      return;
    }

    const payload = {
      content: `**New Bonus Request**\n**Discord Username:** ${discordUsername}\n**Deposit Amount:** $${depositAmount}\n**Selected Site:** ${selectedSite}`
    };

    try {
      const response = await fetch(getWebhookUrl(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setMessage("Your request has been sent successfully!");
        setIsSuccess(true);
        setDiscordUsername('');
        setDepositAmount('');
        setSelectedSite('');
        setRecaptchaValue(null);
      } else {
        setMessage("Failed to send the request. Please try again.");
        setIsSuccess(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage("An error occurred. Please try again.");
      setIsSuccess(false);
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <div className="bonus-forum">
      <h2>Bonus Deposit Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Discord Username:
          <input
            type="text"
            value={discordUsername}
            onChange={(e) => setDiscordUsername(e.target.value)}
            placeholder="e.g., User#1234"
            required
          />
        </label>
        <label>
          Deposit Amount:
          <input
            type="number"
            value={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
            placeholder="Enter amount in USD"
            required
          />
        </label>
        <label>
          Select Site:
          <select
            value={selectedSite}
            onChange={(e) => setSelectedSite(e.target.value)}
            required
          >
            <option value="">Select a site...</option>
            <option value="Diceblox Deposit Bonus">Diceblox Deposit Bonus!</option>
          </select>
        </label>

        {/* reCAPTCHA Component */}
        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey="6LfitBgqAAAAAB7zQT6yiAmZ4jdykYmkv9c3LLES"
            onChange={handleRecaptchaChange}
          />
        </div>

        <button type="submit">Submit</button>
      </form>
      {message && (
        <p className={`message ${isSuccess ? 'success' : 'error'}`}>{message}</p>
      )}
    </div>
  );
};

export default BonusForum;
