// src/Preloader.js
import React, { useEffect, useState } from 'react';
import './Preloader.css';

const Preloader = () => {
    const [loading, setLoading] = useState(true); // State to manage loading status

    useEffect(() => {
        const createBubbles = () => {
            const bubbleCount = 30; // Adjust the number of bubbles
            const preloader = document.querySelector('.preloader');
            
            for (let i = 0; i < bubbleCount; i++) {
                const bubble = document.createElement('div');
                bubble.className = 'bubble';
                
                // Set random size and position
                const size = Math.random() * 20 + 10; // Between 10px and 30px
                bubble.style.width = `${size}px`;
                bubble.style.height = `${size}px`;
                bubble.style.left = `${Math.random() * 100}vw`;
                bubble.style.top = `${Math.random() * 100}vh`;

                // Set random animation duration
                bubble.style.animationDuration = `${Math.random() * 2 + 2}s`; // Between 2s and 4s
                
                // Append bubble to preloader
                preloader.appendChild(bubble);
            }
        };

        createBubbles();

        // Simulate loading complete after 3 seconds for demo purposes
        const timer = setTimeout(() => {
            setLoading(false); // Hide preloader after loading
        }, 3000); // Adjust the duration as needed

        return () => clearTimeout(timer); // Cleanup timer
    }, []);

    // Hide body overflow when loading
    useEffect(() => {
        if (loading) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling
        }
    }, [loading]);

    if (loading) {
        return (
            <div className="preloader">
                <div className="logo-containerpreloader">
                    <img src={`${process.env.PUBLIC_URL}/assets/biggerlogo.webp`} alt="Loading..." className="logoER" />
                </div>
            </div>
        );
    }

    // Render your main app content when loading is complete
    return (
        <div>
            {/* Your main application content goes here */}
            <h1>Welcome to the Home Page!</h1>
        </div>
    );
};

export default Preloader;