// src/components/Navbar/Navbar.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <img 
                    src={`${process.env.PUBLIC_URL}/assets/logose.png`} 
                    alt="Jordanski's Leaderboard Logo" 
                    className="navbar-logo" 
                />
                <NavLink to="/" className="navbar-title">
                    Jordanski's Leaderboard
                </NavLink>            
            </div>
            <div className="navbar-links">
                <NavLink to="/" exact className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    Home
                </NavLink>
                <NavLink to="/leaderboard/diceblox" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    Leaderboard
                </NavLink>
                <NavLink to="/bonus" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    Bonus Form
                </NavLink>
            </div>
        </nav>
    );
};

export default Navbar;