import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '.././global.css'; // Ensure you import your CSS file

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const particlesContainer = document.getElementById('particles-js');

        for (let i = 0; i < 40; i++) {
            const particle = document.createElement('div');
            particle.className = 'floating-particle';

            particle.style.left = `${Math.random() * 100}vw`;
            particle.style.top = `${Math.random() * (window.innerHeight - 20)}px`;

            particle.style.animationDuration = `${10 + Math.random() * 20}s`;
            particle.style.animationDelay = `${Math.random() * 5}s`;

            particlesContainer.appendChild(particle);
        }

        return () => {
            while (particlesContainer.firstChild) {
                particlesContainer.removeChild(particlesContainer.firstChild);
            }
        };
    }, []);

    return (
        <div className="home-container">
                        <div className="image-overlay"></div>
                        <div className="image-overlayse"></div>
            <div id="particles-js" style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}></div>
            
            <h2 className="gradient-text my-leaderboard">
    My Leaderboard
</h2>

            <div className="divider-container" style={{ position: 'relative', zIndex: 2 }}>
                <div className="logo-containerrust">
                    <div className="coming-soon-overlay">Coming Soon</div>
                    <img src={`${process.env.PUBLIC_URL}/assets/rustmagic.png`} alt="Rust Magic" className="leaderboard-logo" />
                    <div className="coin-amountrust">
                        <img src={`${process.env.PUBLIC_URL}/assets/rustcoin.svg`} alt="Coin Icon" className="coin-iconrust" />
                        <span className="amountrust">$1,000</span>
                    </div>
                </div>

                <div className="divider"></div>

                <div
                    className="logo-container"
                    onClick={() => navigate('/leaderboard/diceblox')}
                    style={{ cursor: 'pointer', zIndex: 3, position: 'relative' }}
                >
                    <img src={`${process.env.PUBLIC_URL}/assets/diceblox.png`} alt="Dice Blox" className="leaderboard-logo" />
                    <div className="coin-amount">
                        <img src={`${process.env.PUBLIC_URL}/assets/balance.svg`} alt="Coin Icon" className="coin-icon" />
                        <span className="amount">R$500,000</span>
                    </div>
                </div>

                <div className="divider"></div>

                <div className="logo-containerrush">
                    <div className="coming-soon-overlay">Coming Soon</div>
                    <img src={`${process.env.PUBLIC_URL}/assets/csgobig.png`} alt="CSGO Big" className="leaderboard-logo" />
                    <div className="coin-amountbig">
                        <img src={`${process.env.PUBLIC_URL}/assets/bigcoin.svg`} alt="Coin Icon" className="coin-icon" />
                        <span className="amountrush">1,000</span>
                    </div>
                </div>
            </div>
{/* Bonus Section */}
<div className="bonus-section" style={{ position: 'relative', zIndex: 2, marginTop: '10px', padding: '30px', textAlign: 'center' }}>
    <div className="bonus-content" style={{ maxWidth: '700px', margin: '0 auto', backgroundColor: 'rgba(0, 0, 0, 0.75)', borderRadius: '15px', padding: '20px', color: '#fff' }}>
        <img src={`${process.env.PUBLIC_URL}/assets/diceblox.png`} alt="Diceblox Logo" style={{ width: '150px', margin: '0 auto 10px', display: 'block' }} />
        <h3 style={{ fontSize: '2rem', margin: '10px 0', color: '#af44f7' }}>Earn a Bonus on Your Deposit!</h3>
        <p style={{ fontSize: '1.1rem', marginBottom: '20px' }}>
            Affiliate users on code <strong>Jordan</strong> can receive a <span style={{ color: '#ffd700' }}>10% - 15% deposit bonus</span>! 
            Fill out the form on the <strong>/bonus</strong> page to claim your reward.
        </p>
        <button className="bonus-button" onClick={() => navigate('/bonus')} style={{ backgroundColor: '#af44f7', padding: '12px 25px', borderRadius: '5px', color: '#fff', fontSize: '1rem', cursor: 'pointer', transition: 'background-color 0.3s ease' }}>
            Claim Bonus
        </button>
    </div>
</div>

            {/* Social Media Follow Me Section */}
            <div className="follow-me-section" style={{ position: 'relative', zIndex: 2, marginTop: '30px', marginBottom: '40px', textAlign: 'center' }}>
            <h3 className="gradient-follow">Follow Me</h3>
  <div className="social-cards">
    <div className="social-card" onClick={() => window.open('https://discord.gg/pMAr3XbPKX', '_blank')}>
      <div className="social-icon-circle">
        <img src={`${process.env.PUBLIC_URL}/assets/discordcard.png`} alt="Discord" className="social-icon" />
      </div>
      <h4>Discord</h4>
      <p className="gradient-tetdiscord">Join my Discord Community!</p>
      <button className="follow-button" onClick={() => window.open('https://discord.gg/pMAr3XbPKX', '_blank')}>Join Now</button>
    </div>

    <div className="social-card" onClick={() => window.open('https://www.twitch.tv/fyjordan', '_blank')}>
      <div className="social-icon-circle">
        <img src={`${process.env.PUBLIC_URL}/assets/twitchcard.png`} alt="Twitch" className="social-icon" />
      </div>
      <h4>Twitch</h4>
      <p className="gradient-tettwitch">Follow me on Twitch</p>
      <button className="follow-button" onClick={() => window.open('https://www.twitch.tv/fyjordan', '_blank')}>Follow Now</button>
    </div>

    <div className="social-card" onClick={() => window.open('https://t.me/notjordanski', '_blank')}>
      <div className="social-icon-circle">
        <img src={`${process.env.PUBLIC_URL}/assets/realtelecard.png`} alt="Twitter" className="social-icon" />
      </div>
      <h4>Telegram</h4>
      <p className="gradient-tettwitter">Contact me faster on Telegram!</p>
      <button className="follow-button" onClick={() => window.open('https://t.me/notjordanski', '_blank')}>Contact Now</button>
    </div>
  </div>
            </div>
        </div>
    );
};

export default Home;
